import { FC } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Link from 'next/link'
import styled from 'styled-components'
import HamburgerIcon from 'svgIcons/Hamburger'

interface IFHamburgerMenuButtonProps {
  className?: string
}

const HamburgerMenuButton: FC<IFHamburgerMenuButtonProps> = ({ className }) => {
  return (
    <StyledWrapper>
      <Link href="/nav">
        <a>
          <IconButton className={className}>
            <HamburgerIcon className="hamburger-icon" />
          </IconButton>
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default HamburgerMenuButton

const StyledWrapper = styled.div`
  .hamburger-icon {
    font-size: 16px;
  }
`
